.button-container button {
    background-color: transparent;
    outline: 2px solid lightgreen !important;
    color: lightblue;
    border: none;
    transition: all 0.3s ease;

}

.button-container button:hover {
    color: #050e2f;
    background-color: lightgreen;
    border: none;
    outline: 2px solid lightgreen !important;
}

.resume-modal-header {
    background-color: #050e2f;
    color: lightgreen;
}

.resume-modal-header a {
    color: lightgreen;
}

.btn-close {
    background-color: lightgreen;
    opacity: 1;
}

#download-button button {
    background-color: transparent;
    outline: 2px solid lightgreen;
    color: lightblue;
    border: none;
    transition: all 0.3s ease;

}

#download-button button:hover {
    color: #050e2f;
    background-color: lightgreen;
    border: none;
    outline: 2px solid lightgreen;


}

.nav-resume-button {
    background-color: transparent;
    border: 2px solid #64ffda;
    color: #64ffda;
    padding: 0.5rem 1.2rem;
    font-weight: 500;
    transition: all 0.2s ease;
    margin-left: 1rem;
}

.nav-resume-button:hover,
.nav-resume-button:focus {
    background-color: rgba(100, 255, 218, 0.1);
    border-color: #64ffda;
    color: #64ffda;
    transform: translateY(-2px);
}

.resume-modal .modal-content {
    background: var(--modal-bg);
    border: 1px solid var(--modal-border);
    border-radius: 12px;
    box-shadow: var(--modal-shadow);
}

.resume-modal-header {
    background: var(--modal-header-bg);
    border-bottom: 1px solid var(--modal-border);
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resume-modal-header .modal-title {
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #64ffda;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
    letter-spacing: 0.5px;
}

.modal-actions {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.modal-actions a {
    text-decoration: none;
}

.download-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--accent-primary);
    background: transparent;
    border: 1px solid var(--accent-primary);
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    text-decoration: none;
}

.download-button:hover {
    color: var(--accent-secondary);
    border-color: var(--accent-secondary);
    transform: translateY(-2px);
    background: transparent;
    text-decoration: none;
}

.close-button {
    background: transparent;
    color: var(--accent-primary);
    border: 1px solid var(--accent-primary);
    transition: all 0.3s ease;
}

.close-button:hover {
    color: var(--accent-secondary);
    border-color: var(--accent-secondary);
    transform: scale(1.1);
}

.resume-modal-body {
    background: var(--pdf-viewer-bg);
    padding: 2rem;
}

.pdf-viewer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--pdf-viewer-bg);
    border-radius: 8px;
}

@media (max-width: 768px) {
    .resume-modal-header {
        padding: 1.2rem;
    }

    .resume-modal-header .modal-title {
        font-size: 1.5rem;
    }

    .download-button {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .close-button {
        padding: 0.5rem;
    }
}