.HomePage-container {
    min-height: 100vh;
    color: var(--text-primary);
    position: relative;
}


.HomePage-Info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
}

.HomePage-text {
    display: flex;
    width: max(50%, 200px);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.intro-homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.hi-intro {
    font-size: max(calc(10px + 1vw), 20px);
    color: var(--accent-primary);
}

.wave {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
    padding-right: 5px;
    color: var(--accent-primary);
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
    20% { transform: rotate(-8.0deg) }
    30% { transform: rotate(14.0deg) }
    40% { transform: rotate(-4.0deg) }
    50% { transform: rotate(10.0deg) }
    60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
}

.gradient-text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 1rem;
}

.name-text {
    font-size: 4rem;
    font-weight: 800;
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 1rem 0;
}

.title-text {
    font-size: 2.5rem;
    color: var(--heading-color);
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.description-text {
    display: block;
    color: var(--description-text);
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 540px;
    margin-bottom: 2rem;
    font-weight: 500;
}

.touch-button, .contact-link, .primary-button, .secondary-button {
    background: var(--button-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid transparent;
    background-origin: border-box;
    transition: all 0.3s ease;
}

.touch-button:hover, .contact-link:hover, .primary-button:hover, .secondary-button:hover {
    background: var(--button-hover-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateY(-2px);
}

.descriptions-extended {
    width: max(50%, 200px);
    height: 100%;
    display: flex;
    font-size: max(calc(10px + 0.5vw), 15px);
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: var(--text-secondary);
}

.descriptions-extended span {
    max-width: 45%;
}
.descriptions-extended h1 {
    color: var(--accent-primary);
    font-size: max(calc(10px + 1vw), 20px);
    align-self: center;
}

.centered-descriptions{
    display: flex;
    flex-direction: column;
    height: max(60%, 300px);
    justify-content: space-between;
}

.program-icons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    padding-top: 20px;
}

.programming-icon {
    transition: all 0.3s ease-in-out;
    opacity: var(--program-icon-opacity);
}
.program-icon p {
    font-size: smaller;
}
.programming-icon:hover {
    opacity: var(--program-icon-hover);
    transform: translateY(-5px);
}

.programming-languages {
    padding: 4rem 0;
}

.scroller {
    max-width: 100%;
    overflow: hidden;
}

.scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: nowrap;
    gap: 3rem;
    animation: scroll 30s linear infinite;
}

@keyframes scroll {
    to {
        transform: translateX(calc(-50% - 1.5rem));
    }
}

.scroller__inner:hover {
    animation-play-state: paused;
}

.hero-section {
    position: relative;
    padding: 6rem 0;
    margin-bottom: 4rem;
}

.hero-section::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 1px;
    background: var(--scroller-gradient);
}

.hero-content {
    padding: 2rem 4rem;
}

.hero-content h1 {
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.greeting {
    color: #64ffda;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: block;
}

.name {
    font-size: 4.5rem;
    font-weight: 800;
    background: linear-gradient(90deg, #64ffda, #00b4d8);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
}

.title {
    font-size: 2.5rem;
    color: #8892b0;
    margin-bottom: 1.5rem;
}

.bio {
    color: #8892b0;
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 600px;
    margin-bottom: 2rem;
}

.cta-buttons {
    display: flex;
    gap: 1rem;
}

.cta-buttons .btn {
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    min-width: 140px;
    text-align: center;
}

.cta-buttons .primary-button {
    color: var(--primary-button-text);
    background: var(--button-gradient);
    -webkit-text-fill-color: var(--primary-button-text);
    background-clip: padding-box;
    border: 2px solid transparent;
}

.cta-buttons .secondary-button {
    position: relative;
    background: var(--button-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid transparent;
    background-origin: border-box;
}

.cta-buttons .secondary-button::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    padding: 2px;
    background: var(--button-gradient);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.section-title, .contact-title {
    position: relative;
    display: inline-block;
    margin-bottom: 3rem;
    padding-bottom: 1rem;
    color: var(--expertise-card-title);
    font-weight: 700;
    text-align: center;
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: var(--button-gradient);
    border-radius: 2px;
}

.expertise-section {
    margin-bottom: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.expertise-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 1px;
    background: linear-gradient(
        to right,
        rgba(100, 255, 218, 0),
        rgba(100, 255, 218, 0.3),
        rgba(100, 255, 218, 0)
    );
}

.expertise-card {
    background: var(--expertise-card-bg);
    padding: 2rem;
    border-radius: 12px;
    height: 100%;
    border: 1px solid var(--expertise-card-border);
    box-shadow: var(--expertise-card-shadow);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(10px);
    opacity: 0;
    will-change: opacity, transform;
    transform: translateZ(0);
}

.expertise-card.in-view {
    opacity: 1;
}

.expertise-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg,
        rgba(100, 255, 218, 0.1),
        rgba(100, 255, 218, 0)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
}

.expertise-card:hover {
    transform: translateY(-8px);
    background: var(--expertise-card-hover-bg);
    border-color: var(--expertise-card-border);
    box-shadow: var(--expertise-card-hover-shadow);
}

.expertise-card:hover::before {
    opacity: 1;
}

.expertise-card h3 {
    color: var(--expertise-card-title);
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.expertise-card p {
    color: var(--expertise-card-text);
    line-height: 1.6;
}

.tech-stack-row {
    text-align: center;
}

.tech-stack-section {
    position: relative;
    padding: 4rem 0;
}

.tech-stack-section .section-title {
    width: 100%;
    text-align: center;
    color: #e6f1ff;
    font-size: 2rem;
    margin-bottom: 3rem;
}

.tech-stack-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 1px;
    background: linear-gradient(
        to right,
        rgba(100, 255, 218, 0),
        rgba(100, 255, 218, 0.3),
        rgba(100, 255, 218, 0)
    );
}

.tech-grid-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 2rem 0;
}

.tech-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 95%;
}

.tech-icon-wrapper {
    flex: 1 1 calc(8% - 1rem);
    max-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    /* transition: transform 0.3s ease-in-out; */
    opacity: 0;
    will-change: opacity, transform;
    transform: translateZ(0);
}

.tech-icon-wrapper.in-view {
    opacity: 1;
}

.tech-icon-wrapper:hover {
    opacity: 1;
    scale: 1.3;
    transition: all 0.3s ease-in-out;
    transform: translateY(-5px);
    border: none;
    box-shadow: none;   
}

.tech-icon-wrapper:hover .tech-icon-title {
    opacity: 1;
}

.tech-icon-title {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.9rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;
    z-index: 10;
}

.tech-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8892b0;
    /* transition: color 0.3s ease-in-out; */
}

.tech-icon-wrapper:hover .tech-icon {
    /* color: #64ffda; */
}

.tech-icon-wrapper img {
    width: 100%;
    height: auto;
}

.contact-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 4rem;
}

.contact-content {
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    background: var(--expertise-card-bg);
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid var(--expertise-card-border);
    box-shadow: var(--expertise-card-shadow);
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(10px);
    width: 100%;
}

.contact-content:hover {
    background: var(--expertise-card-hover-bg);
    border-color: var(--expertise-card-border);
    box-shadow: var(--expertise-card-hover-shadow);
}

.contact-title {
    color: var(--expertise-card-title);
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
}

.contact-subtitle {
    color: #8892b0;
    font-size: 1.1rem;
    margin-top: auto;
}

.contact-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.contact-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    color: var(--text-primary);
    text-decoration: none;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    background: var(--button-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid var(--expertise-card-border);
    transition: all 0.3s ease;
}

.contact-link:hover {
    color: var(--accent-primary);
    background: var(--button-hover-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: var(--accent-primary);
    transform: translateX(5px);
}

.contact-link span {
    font-size: 1.1rem;
}

.contact-link svg {
    fill: var(--accent-primary);
}

.scroll-indicator-home {
    position: fixed;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--gradient-text);
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    gap: 0.5rem;
    cursor: pointer;
    z-index: 10;
    pointer-events: none;
    transition: opacity 0.8s ease-in-out;
}

@media (max-width: 1200px) {
    .tech-grid {
        padding: 2rem 3rem;
    }
    .expertise-section {
        padding: 3rem 1rem;
    }
}

@media (max-width: 992px) {
    .tech-grid {
        padding: 2rem;
        flex-wrap: wrap;
        gap: 2rem;
    }
    
    .tech-icon-wrapper {
        flex: 0 0 calc(20% - 1.6rem);
    }

    .contact-section {
        width: 100%;
        justify-content: center;
    }

    .contact-title {
        text-align: center;
    }

    .contact-section {
        padding: 0.8rem 1.6rem;
    }  
}

@media (max-width: 768px) {
    .tech-icon-wrapper {
        flex: 0 0 calc(33.333% - 1rem);
        align-items: center;
        justify-items: center;
    }

    .tech-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        gap: 1rem;
        justify-items: center;
        padding: 2rem 0;
    }

    .section-title {
        font-size: 1.75rem;
        margin-bottom: 2rem;
    }
    
    .expertise-section {
        padding: 2rem 0.75rem;
    }
    
    .expertise-card {
        margin: 0.5rem;
    }

    .contact-section {
        padding: 0.2rem 0.2rem;
    }    
}

@media (max-width: 576px) {
    .tech-icon-wrapper {
        flex: 0 0 calc(33.333% - 1rem);
    }
    
    .tech-grid {
        padding: 1rem;
    }
    
    .section-title {
        font-size: 1.75rem;
        margin-bottom: 2rem;
    }

    .contact-section {
        padding: 0 0;
    }    
}

@media (max-width: 480px) {
    .tech-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding: 0.5rem;
    }
}

a {
    color: var(--link-color);
    transition: all 0.3s ease;
}

a:hover {
    color: var(--link-hover);
}

h1, h2 {
    color: var(--heading-color);
}

.greeting {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}


.scroll-indicator-home:hover {
    background: var(--button-hover-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}