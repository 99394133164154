.template-project {
    padding: 2rem;
    color: var(--project-text);
    max-width: 1800px;
    margin: 0 auto;
}

.project-grid {
    display: grid;
    gap: 2rem;
    grid-template-areas:
        "header image"
        "description description"
        "role contributions";
    /* Force equal columns for the bottom row */
    grid-template-columns: repeat(2, 1fr);
}

.project-media {
    grid-area: image;
    max-width: 400px;
    justify-self: end;
}

.project-header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.description-section {
    grid-area: description;
    /* Span both columns */
    grid-column: 1 / -1;
}

.role-section {
    grid-area: role;
    /* Force first column */
    grid-column: 1 / 2;
}

.contributions-section {
    grid-area: contributions;
    /* Force second column */
    grid-column: 2 / 3;
}

/* Remove any previous width constraints */
.role-section,
.contributions-section {
    min-width: 0;
    width: 100%;
}

.project-header {
    text-align: left;
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.project-header h1 {
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.timeline {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--project-text);
    font-size: 1.2rem;
}

.project-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.5rem;  /* Increased gap for more space between elements */
}

.project-info {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-right: 2rem;
}

.description-section,
.role-section,
.contributions-section {
    background: var(--project-card-bg);
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid var(--project-card-border);
    box-shadow: var(--project-card-shadow);
    height: 100%;
    transition: all 0.3s ease;
}

.description-section:hover,
.role-section:hover,
.contributions-section:hover {
    background: var(--project-card-hover);
    box-shadow: var(--project-card-hover-shadow);
    transform: translateY(-5px);
}

.section-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.section-header h2 {
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
}

.section-header svg {
    width: 22px;
    height: 22px;
}

.description-section h2 {
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.description-section p {
    color: var(--project-text);
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.6;
    margin: 0;
}

.feature-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature-list li {
    color: var(--project-text);
    font-size: 1.2rem;
    padding-left: 1.5rem;
    position: relative;
    line-height: 1.5;
}

.feature-list li::before {
    content: "▹";
    position: absolute;
    left: 0;
    color: var(--accent-primary);
    font-size: 1.2rem;
}

.project-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--project-card-bg);
    border: 1px solid var(--project-card-border);
    border-radius: 12px;
    box-shadow: var(--project-card-shadow);
    transition: all 0.3s ease;
}

.project-card:hover {
    transform: translateY(-5px);
    background: var(--project-card-hover);
    box-shadow: var(--project-card-hover-shadow);
}

.project-image-container {
    background: var(--project-image-bg);
    transition: all 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.project-card img {
    width: 100%;
    height: 220px;
    object-fit: contain;
    border-radius: 12px 12px 0 0;
    transition: all 0.3s ease-in-out;
    padding: 1rem;
    background: var(--project-image-bg);
}

.project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.image-container:hover .project-image {
    transform: scale(1.02);
}

.project-content h3 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    height: 4.2rem;  /* Fixed height for title */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;  /* Remove margin to use gap instead */
}

.project-content p {
    flex: 1;
    margin: 0;  /* Remove margin to use gap instead */
}

.learn-more-button {
    background: var(--button-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid transparent;
    position: relative;
}

.learn-more-button::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    padding: 2px;
    background: var(--button-gradient);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.learn-more-button:hover {
    background: var(--button-hover-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateY(-2px);
}

@media (max-width: 1200px) {
    .project-header h1 {
        font-size: 2.7rem;
    }

    .section-header h2 {
        font-size: 1.7rem;
    }

    .description-section h2 {
        font-size: 2rem;
    }

    .feature-list li,
    .description-section p,
    .timeline {
        font-size: 1.2rem;
    }
}

@media (max-width: 992px) {
    .project-grid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "image"
            "header"
            "description"
            "role"
            "contributions";
        gap: 1.5rem;
    }

    .role-section,
    .contributions-section {
        grid-column: 1 / -1; /* Span full width on mobile */
    }

    .project-media {
        max-width: 100%;
        justify-self: center;
    }

    .project-header {
        text-align: center;
        margin: 1rem 0;
    }

    .project-header h1 {
        font-size: 2.3rem;
    }

    .section-header h2 {
        font-size: 1.6rem;
    }

    .description-section h2 {
        font-size: 1.8rem;
    }

    .feature-list li,
    .description-section p,
    .timeline {
        font-size: 1.15rem;
    }

    .timeline {
        justify-content: center;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .template-project {
        padding: 1rem;
    }

    .project-header {
        margin-bottom: 2rem;
    }

    .project-header h1 {
        font-size: 2.2rem;
    }

    .section-header h2 {
        font-size: 1.5rem;
    }

    .description-section h2 {
        font-size: 1.7rem;
    }

    .feature-list li,
    .description-section p,
    .timeline {
        font-size: 1.1rem;
    }
}

.modal-content {
    background: var(--modal-bg);
    border: 1px solid var(--project-card-border);
}

.modal-header {
    border-bottom: 1px solid var(--project-card-border);
    background: var(--modal-header-bg);
}

.modal-header h2 {
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.modal-body {
    color: var(--project-text);
    background: var(--modal-bg);
}

.modal-footer {
    border-top: 1px solid var(--project-card-border);
    background: var(--modal-bg);
}

.modal-header .btn-close {
    background: var(--button-gradient);
    opacity: 0.8;
    transition: all 0.3s ease;
}

.modal-header .btn-close:hover {
    background: var(--button-hover-gradient);
    opacity: 1;
    transform: scale(1.1);
}

/* Update theme variables in theme.css */
:root[data-theme="light"] {
    --project-card-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    --project-card-hover-shadow: rgba(0, 0, 0, 0.15) 0px 8px 24px;
}

:root[data-theme="dark"] {
    --project-card-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    --project-card-hover-shadow: rgba(0, 0, 0, 0.3) 0px 8px 24px;
}

.project-card-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--project-card-bg);
    border: 1px solid var(--project-card-border);
    border-radius: 12px;
    box-shadow: var(--project-card-shadow);
    transition: all 0.3s ease;
}

.project-card-inner:hover {
    transform: translateY(-5px);
    background: var(--project-card-hover);
    box-shadow: var(--project-card-hover-shadow);
} 