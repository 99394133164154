.RecommendationPage-container {
    height: 100%;
    width: max(100%, 600px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    /* padding-top: 30px; */
    padding-bottom: 50px;

}

.rec-header {
    background-color: var(--rec-header-bg);
    width: 100%;
    top: max(calc(40px + 3vh), 60px);
    height: max(15%, 100px);
    padding-top: 30px;
    z-index: 12;
    position: fixed;
    border-bottom: 5px solid var(--rec-header-border);

}

.rec-header-flex {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 5px solid var(--rec-header-border);
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: var(--rec-header-bg);
    justify-content: center;
}

.rec-header-flex h1 {
    color: var(--rec-title);
}

.letter-wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    /* display: grid;
    padding-top: 50px;
    /* align-items: center; */
    justify-content: space-evenly;

    /* row-gap: 30px;
    grid-template-columns: repeat(auto-fit, 50%);
    place-items: center; */
    /* justify-content: center; */
    /* justify-items: center; */
    /* grid-auto-columns: 50%; */
    /* grid-auto-flow: column; */
    /* flex-direction: row; */
    /* align-items: center; */
    /* justify-content: space-evenly; */
    z-index: 1;
}

.letter-wrapper h1 {
    color: var(--rec-title);
    font-size: max(calc(10px + 1vw), 20px);
}

.letter-wrapper h2 {
    color: var(--accent-primary);
    font-size: max(calc(10px + 0.8vw), 18px);
}

.letter-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.letter-grid-wrapper{
    padding: 30px;
    margin: 10px;
}

.recommendation-page {
    min-height: 100vh;
    color: var(--rec-text);
    padding-bottom: 4rem;
    width: 100%;
}

.page-header {
    text-align: center;
    padding: 2rem 0;
    width: 100%;
}

.page-header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #64ffda;
    margin: 0;
}

.highlight-bar {
    width: 100px;
    height: 4px;
    margin: 1rem auto;
    background: var(--button-gradient);
    border-radius: 2px;
}

.header-description {
    color: #8892b0;
    font-size: 1.2rem;
    max-width: 800px;
    margin: 1.5rem auto 0;
    line-height: 1.6;
}

.recommendations-container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 2rem 0;
}

.recommendation-section {
    max-width: 100%;
    justify-self: center;
    margin-bottom: 4rem;
    padding: 0 2rem;
}

.recommendation-section:last-child {
    margin-bottom: 0;
}

.recommendation-content {
    background: var(--rec-card-bg);
    border-radius: 12px;
    border: 1px solid var(--rec-card-border);
    box-shadow: var(--rec-card-shadow);
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.recommendation-content:hover {
    transform: translateY(-8px);
    background: var(--rec-card-hover);
    border-color: var(--accent-primary);
    box-shadow: 
        rgba(0, 0, 0, 0.35) 0px 35px 60px -15px,
        rgba(0, 0, 0, 0.4) 0px 20px 30px -8px;
}

.recommendation-info {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quote-icon {
    color: var(--accent-primary);
    margin-bottom: 1rem;
    transition: color 0.3s ease;
}

.recommendation-content:hover .quote-icon {
    color: var(--accent-secondary);
}

.recommendation-info h2 {
    color: var(--heading-color);
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
}

.recommendation-info h3 {
    color: var(--accent-primary);
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
}

.recommendation-info p {
    color: var(--rec-text);
    line-height: 1.6;
    font-size: 1.1rem;
}

.pdf-container {
    background: var(--pdf-container-bg);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 12px 12px 0;
}

.pdf-wrapper {
    max-width: 100%;
    overflow: auto;
    border-radius: 8px;
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
}

.pdf-wrapper canvas {
    max-width: 100%;
    height: auto !important;
}

/* Loading animation for PDFs */
.react-pdf__Document {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.react-pdf__Document.loading {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 0.8; }
    100% { opacity: 0.6; }
}

@media (max-width: 1200px) {
    .recommendation-section {
        padding: 0 1.5rem;
    }
}

@media (max-width: 992px) {
    .recommendation-content {
        flex-direction: column;
    }

    .recommendation-info {
        padding: 2rem;
        text-align: center;
    }

    .quote-icon {
        margin: 0 auto 1.5rem;
    }

    .pdf-container {
        padding: 1.5rem;
    }
}

@media (max-width: 768px) {
    .page-header h1 {
        font-size: 2rem;
    }

    .header-description {
        font-size: 1.1rem;
        padding: 0 1.5rem;
    }

    .recommendation-section {
        padding: 0 1rem;
        margin-bottom: 3rem;
    }

    .recommendation-info h2 {
        font-size: 1.6rem;
    }

    .recommendation-info h3 {
        font-size: 1.1rem;
    }

    .recommendation-info p {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .recommendation-info {
        padding: 1.5rem;
    }

    .pdf-container {
        padding: 1rem;
    }
}

