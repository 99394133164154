.background-page {
    min-height: 100vh;
    color: #e6f1ff;
    padding: 2rem 0;
}

/* Section Styling */
.intro-section,
.skills-section,
.education-section {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 5rem 4rem;
}

/* Modern Section Dividers */
.intro-section::after,
.skills-section::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 1px;
    background: var(--section-divider-gradient);
}

/* Section Headers */
.section-header {
    text-align: center;
    margin-bottom: 4rem;
    position: relative;
}

.section-header h2 {
    font-size: 2.5rem;
    color: #e6f1ff;
    margin-bottom: 1rem;
}

/* Profile Section */
.profile-image-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image {
    max-width: 300px;
    width: 100%;
    height: auto;
    border: 2px solid transparent;
    border-radius: 8px;
    background: 
        linear-gradient(to right, white, white) padding-box,
        var(--profile-image-border) border-box;
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
    transition: all 0.3s ease;
}

.profile-image:hover {
    transform: translateY(-8px);
    border-color: rgba(100, 255, 218, 0.5);
}

/* Skills Grid */
.skills-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 2rem 0;
    justify-content: center;
}

.skill-card {
    flex: 0 1 calc(33.333% - 2rem);
    min-width: 300px;
    max-width: 500px;
    background: var(--skill-card-bg);
    padding: 2.5rem;
    border-radius: 8px;
    border: 1px solid var(--skill-card-border);
    box-shadow: var(--skill-card-shadow);
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
    opacity: 0;
    will-change: opacity, transform;
    transform: translateZ(0);
    position: relative;
    overflow: hidden;
}

.skill-card.in-view {
    opacity: 1;
}

.skill-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg,
        rgba(100, 255, 218, 0.1),
        rgba(100, 255, 218, 0)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
}

.skill-card:hover {
    transform: translateY(-8px);
    background: var(--skill-card-hover);
    border-color: rgba(100, 255, 218, 0.25);
    box-shadow: var(--skill-card-hover-shadow);
}

.skill-card:hover::before {
    opacity: 1;
}

.skill-card h3 {
    background: var(--skill-title);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skill-card p {
    color: var(--bg-page-text);
}

/* Education Section */
.education-content {
    background: var(--skill-card-bg);
    border: 1px solid var(--skill-card-border);
    box-shadow: var(--skill-card-shadow);
    padding: 2rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.education-content:hover {
    background: var(--skill-card-hover);
    box-shadow: var(--skill-card-hover-shadow);
}

.education-content h2 {
    background: var(--bg-page-title);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.education-list {
    list-style: none;
    margin-top: 2rem;
    padding: 0;
}

.education-list li {
    margin-bottom: 1rem;
    position: relative;
    padding-left: 30px;
    color: var(--bg-page-text);
    line-height: 1.6;
}

.education-list li::before {
    content: "▹";
    color: var(--bullet-point-color);
    position: absolute;
    left: 0;
}

.education-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.education-logo img {
    max-width: 450px;
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    transition: opacity 0.3s ease;
}

.education-logo img:hover {
    opacity: 0.9;
}

.education-content a {
    color: var(--education-link);
    text-decoration: none;
    transition: color 0.3s ease;
}

.education-content a:hover {
    color: var(--education-link-hover);
}

/* Responsive Design */
@media (max-width: 1400px) {
    .intro-section,
    .skills-section,
    .education-section {
        padding: 4rem 3rem;
    }
}

@media (max-width: 1200px) {
    .intro-section,
    .skills-section,
    .education-section {
        padding: 3rem 2rem;
    }
    
    .skill-card {
        flex: 0 1 calc(50% - 1.5rem);
    }
    
    .education-logo img {
        max-width: 400px;
    }
}

@media (max-width: 768px) {
    .intro-section,
    .skills-section,
    .education-section {
        padding: 2rem 1.5rem;
    }
    
    .section-header h2 {
        font-size: 2rem;
    }
    
    .skill-card {
        flex: 0 1 100%;
        max-width: 500px;
    }
    
    .university a {
        font-size: 1.5rem;
    }
    
    .profile-image {
        max-width: 250px;
    }
    
    .education-logo img {
        max-width: 300px;
    }
}

@media (max-width: 576px) {
    .profile-image-col {
        margin-bottom: 1.5rem;
    }

    .page-header {
        margin-bottom: 1rem;
    }

    .intro-section {
        margin-top: 1rem;
    }

    .highlight-bar {
        margin-bottom: 0.5rem;
    }

    .image-wrapper {
        margin-bottom: 1rem;
    }

    .profile-image-col {
        margin-bottom: 1rem;
    }

    .intro-section {
        padding-top: 0;
    }
}

.intro-content h2 {
    background: var(--bg-page-title);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lead-text {
    color: var(--bg-page-text);
    font-size: 1.2rem;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.about-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.about-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 1rem;
    color: var(--bg-page-text);
    line-height: 1.6;
}

.about-list li::before {
    content: "▹";
    color: var(--bullet-point-color);
    position: absolute;
    left: 0;
}

.skills-section h2 {
    background: var(--bg-page-title);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.location {
    color: var(--bg-page-text);
    font-style: italic;
    margin: 0.5rem 0;
}

.scroll-indicator-background {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    background: var(--scroll-indicator-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 10;
    transition: all 0.3s ease;
}

.scroll-indicator-background:hover {
    background: var(--scroll-indicator-hover-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 480px) {
    .section-header h2 {
        font-size: 2rem;
    }

    .skill-card {
        padding: 1.5rem;
    }
}

.page-header h1 {
    background: var(--bg-page-title);
    background-clip: text;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lead-text, .about-list li {
    color: var(--bg-page-text);
}

.skill-card {
    background: var(--skill-card-bg);
    border: 1px solid var(--skill-card-border);
    box-shadow: var(--skill-card-shadow);
}

.skill-card:hover {
    background: var(--skill-card-hover);
    box-shadow: var(--skill-card-hover-shadow);
}

.skill-card h3 {
    background: var(--skill-title);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skill-card p {
    color: var(--bg-page-text);
}

.education-list li {
    color: var(--bg-page-text);
}

.education-content a {
    color: var(--education-link);
    text-decoration: none;
    transition: color 0.3s ease;
}

.education-content a:hover {
    color: var(--education-link-hover);
}

.education-content h2 {
    background: var(--bg-page-title);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.education-content a {
    color: var(--education-link);
    text-decoration: none;
    transition: color 0.3s ease;
}

.education-content a:hover {
    color: var(--education-link-hover);
}

.location {
    color: var(--bg-page-text);
}

.highlight-bar {
    background: var(--highlight-bar-color);
    height: 3px;
    width: 60px;
    margin: 1rem auto;
}