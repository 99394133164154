/* Smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Add this rule to prevent horizontal scrolling */
body {
  overflow-x: hidden;
}

.App {
  min-height: 100vh;
  position: relative;
  background-color: var(--bg-primary);
  transition: all 0.3s ease;
}

.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.nav-visible {
  transform: translateY(0);
}

.nav-hidden {
  transform: translateY(-100%);
}

.content-wrapper-light {
  padding-top: 80px; /* Height of navbar */
  min-height: calc(100vh - 80px);
  width: 100%;
  overflow-x: hidden;
  background: var(--content-wrapper-bg);
  transition: all 0.3s ease;
}

.content-wrapper-dark {
  padding-top: 80px; /* Height of navbar */
  min-height: calc(100vh - 80px);
  width: 100%;
  overflow-x: hidden;
  background-color: var(--bg-primary);
  transition: all 0.3s ease;
}

.extra-pad-light {
  background: var(--extra-pad-bg);
  box-shadow: var(--nav-shadow);
  height: 3rem;
  width: 100vw;
}

.extra-pad-dark {
  background-color: var(--bg-secondary);
  box-shadow: var(--nav-shadow);
  height: 3rem;
  width: 100vw;
}

@media (max-width: 991px) {
  .content-wrapper {
    padding-top: 65px; /* Adjusted for smaller navbar height on mobile */
    min-height: calc(100vh - 65px);
  }
}