.contact-page {
    min-height: 100vh;
    color: #e6f1ff;
    padding: 2rem 0;
}

.page-header {
    text-align: center;
    margin-bottom: 3rem;
}

.page-header h1 {
    font-size: 2.5rem;
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
}

.highlight-bar {
    width: 60px;
    height: 4px;
    background: var(--button-gradient);
    margin: 1rem auto;
}

.header-description {
    color: var(--contact-text);
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto;
}

.contact-container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.contact-row {
    display: flex;
    background: var(--contact-card-bg);
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid var(--contact-card-border);
    box-shadow: var(--contact-card-shadow);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.contact-row:hover {
    transform: translateY(-12px);
    background: var(--contact-card-hover);
    border-color: var(--accent-primary);
    box-shadow: 
        rgba(0, 0, 0, 0.4) 0px 40px 70px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 50px -10px;
}

.contact-info-col, 
.contact-form-col {
    display: flex;
    align-items: flex-start;
}

.contact-info {
    padding: 3rem;
    width: 100%;
}

.contact-info h2,
.contact-form h2 {
    color: #ccd6f6;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-description {
    color: var(--contact-text);
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.contact-links {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contact-link {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--contact-link-color);
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 1.1rem;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: var(--accent-primary);
    transition: all 0.3s ease;
}

.contact-link:hover .icon-wrapper {
    color: var(--accent-secondary);
    transform: translateY(-2px);
}

.contact-form-col {
    border-left: 1px solid var(--contact-card-border);
}

.contact-form {
    padding: 3rem;
    width: 100%;
    background: var(--contact-card-bg);
    /* border: 1px solid var(--contact-card-border); */
    transition: background 0.3s ease;
}

.contact-form:hover {
    background: var(--contact-card-hover);
}

.contact-form h2 {
    background: var(--heading-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form-label {
    color: var(--contact-text);
}

.form-control {
    background: var(--contact-input-bg);
    border: 1px solid var(--contact-input-border);
    color: var(--contact-input-text);
    transition: all 0.3s ease;
}

.form-control:hover {
    border-color: var(--accent-primary);
}

.form-control:focus {
    background: var(--contact-input-bg);
    border-color: var(--accent-primary);
    box-shadow: 0 0 0 0.2rem var(--accent-primary-transparent);
    color: var(--contact-input-text);
}

.form-control.is-valid {
    border-color: var(--accent-primary);
}

.form-control.is-valid:focus {
    border-color: var(--accent-primary);
    box-shadow: 0 0 0 0.2rem rgba(100, 255, 218, 0.1);
}

.form-control::placeholder {
    color: #bac1d7;
}

.submit-button {
    color: var(--accent-primary);
    background: transparent;
    border: 1px solid var(--accent-primary);
    transition: all 0.3s ease;
}

.submit-button:hover:not(:disabled) {
    color: var(--primary-button-text);
    background: var(--accent-primary);
    border-color: var(--accent-primary);
    transform: translateY(-2px);
}

.submit-button:disabled {
    opacity: 1;
    cursor: not-allowed;
    color: var(--disabled-button-text);
    border-color: var(--disabled-button-text);
    background: transparent;
    -webkit-text-fill-color: var(--disabled-button-text);
}

/* Toast Customization */
.Toastify__toast {
    background: #112240 !important;
    color: #e6f1ff !important;
    border: 1px solid rgba(100, 255, 218, 0.1);
    border-radius: 8px !important;
}

.Toastify__toast--success {
    border-color: #64ffda !important;
}

.Toastify__toast--error {
    border-color: #ff6b6b !important;
}

@media (max-width: 992px) {
    .contact-container {
        padding: 0 2rem;
    }

    .contact-info,
    .contact-form {
        padding: 3rem;
    }
}

@media (max-width: 768px) {
    .container-fluid {
        padding: 3rem 0;
    }

    .page-header h1 {
        font-size: 2.4rem;
    }

    .contact-row {
        flex-direction: column;
        margin: 0 1rem;
    }

    .contact-info,
    .contact-form {
        padding: 2rem;
    }

    .contact-form-col {
        border-left: none;
        border-top: 1px solid rgba(100, 255, 218, 0.1);
    }

    .contact-info h2,
    .contact-form h2 {
        font-size: 1.8rem;
    }
}

@media (max-width: 576px) {
    .contact-container {
        padding: 0 1rem;
    }

    .contact-info,
    .contact-form {
        padding: 1.5rem;
    }
}